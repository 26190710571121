import { ServiceFactory } from '@/services/ServiceFactory'
import { mapActions } from 'vuex'
import moment from 'moment'
const _barsService = ServiceFactory.get('BarsService')
export default {
  name: 'ZbTabNewsCategoryComponent',
  data () {
    return {
      loadingTable: true,
      companies: [],
      headers: [
        {
          text: 'icon',
          value: 'id',
          sortable: false
        },
        {
          text: 'Nombre',
          value: 'name',
          sortable: false
        },
        {
          text: '',
          value: 'isPremium',
          sortable: false
        },
        {
          text: 'Ciudad',
          value: 'city',
          sortable: false
        },
        {
          text: 'Inicio',
          value: 'activatedAt',
          sortable: false
        },
        {
          text: 'Actividad',
          value: 'activityTime',
          sortable: false
        },
        {
          text: 'Puntuación',
          value: 'avgRating',
          sortable: false
        },
        {
          text: 'Estado',
          value: 'state',
          sortable: false
        }
        // {
        //   text: this.$t('general.delete'),
        //   value: 'action',
        //   sortable: false
        // }
      ],
      barState: false,
      page: 1,
      pageCount: 0,
      searchText: ''
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    ...mapActions([
      'companiesModule/setCompanyData'
    ]),
    async initialize () {
      this.loadingTable = true
      try {
        this.companies = await _barsService.index(this.page)
        this.loadingTable = false
        this.pageCount = Math.ceil(this.companies.total / 50)
      } catch (error) {
        this.loadingTable = false
        this.$swal({
          type: 'error',
          text: 'Ocurrió un error al obtener la lista de los negocios, recargue la página'
        }).then(async (result) => {})
      }
    },
    editCategory (barId) {
      // console.log(barId)
      let id = barId
      // this.$store.dispatch('companiesModule/setCompanyData', companyData)
      this['companiesModule/setCompanyData'](barId)
      // this.$router.push({ name: 'companies-tabs' })
      this.$router.push({ name: 'companies-tabs', params: { id } })
    },
    destroyCategory (id) {
      this.$swal({
        title: '¿Está seguro de eliminar el negocio?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // await _newsCategoriesService.destroy(id)
            // console.log('Bar id: ', id)
            this.$swal(
              'Negocio eliminado',
              'Se eliminó correctamente',
              'success'
            ).then(async (result) => {
              this.initialize()
            })
          } catch (error) {
            this.$notify({
              title: 'Error:',
              type: 'error',
              text: error.message + ', no se pudo eliminar.'
            })
          }
        }
      })
    },
    async editState (id, itemState) {
      this.companies.active = !this.companies.active
      // console.log(id, itemState)
      this.barState = {
        active: itemState
      }
      try {
        const barStateService = await _barsService.editState(id, this.barState)
        // console.log(this.barState)
        // console.log(barStateService)
        if (this.companies.active === false) {
          this.$swal(
            'Bar habilitado',
            'Se modificó correctamente',
            'success'
          ).then(async (result) => {
            // this.initialize()
          })
        } else {
          this.$swal(
            'Bar inhabilitado',
            'Se modificó correctamente',
            'success'
          ).then(async (result) => {
            // this.initialize()
          })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async parseSearchString () {
      try {
        // console.log(this.searchText)
        const searchResult = await _barsService.searchBar(this.searchText)
        // console.log(searchResult)
        this.companies = searchResult
      } catch (error) {
        // console.log(error)
      }
    },
    changeInput () {
      if (!this.searchText) {
        this.initialize()
      }
    },
    goNewForm (payload = null) {
      this.$router.push({ name: 'companies-form' })
    },
    diffDate (date) {
      // console.log(date)
      var curr = moment()
      var fdate = moment(date)
      var diff = curr.diff(fdate, 'months')
      // console.log(curr.diff(fdate, 'months'))
      return diff + ' meses'
    },
    previous () {
      // alert('prev')
      this.initialize()
    },
    next () {
      // alert('next')
      this.initialize()
    },
    toPage (p) {
      // alert(p)
      this.initialize()
    }
  }
}
