var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-2 mb-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('div',{staticClass:"pt-3 pb-4 d-flex align-center"},[_c('v-text-field',{staticStyle:{"max-width":"350px","width":"100%"},attrs:{"solo":"","label":"Buscar negocios","clearable":"","light":"","dense":"","hide-details":""},on:{"change":function($event){return _vm.changeInput()}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.parseSearchString($event)}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('v-btn',{attrs:{"color":"#ff5a5f","dark":""},on:{"click":function($event){return _vm.parseSearchString()}}},[_c('v-icon',[_vm._v("fas fa-search")])],1)],1)])],1),_c('v-card',[_c('v-data-table',{staticClass:"mx-2",attrs:{"headers":_vm.headers,"items":_vm.companies.bars,"loading":_vm.loadingTable,"disable-pagination":"","hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(item,id){return _c('th',{key:id,staticClass:"text-start",attrs:{"role":"columnheader","scope":"col","aria-label":"Imagen"}},[(id !== 0)?_c('span',[_vm._v(_vm._s(item.text))]):_c('span',[_c('v-btn',{directives:[{name:"permissions",rawName:"v-permissions",value:(['CREATE']),expression:"['CREATE']"}],attrs:{"fab":"","small":"","color":"#ff5a5f","text":""},on:{"click":function($event){return _vm.goNewForm()}}},[_c('v-icon',[_vm._v("fas fa-plus")])],1)],1)])}),0)])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [(item.images)?_c('div',[_c('v-avatar',[_c('img',{class:item.available ? '' : 'o',attrs:{"src":item.images.profile,"alt":item.name}})])],1):_vm._e()]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-0",attrs:{"color":"#ff5a5f","label":"","outlined":""},on:{"click":function($event){return _vm.editCategory(item.zbBarId)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.isPremium",fn:function(ref){
var item = ref.item;
return [(item.isPremium)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("$vuetify.icons.iconCertificate")]):_vm._e()]}},{key:"item.activityTime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.diffDate(item.activatedAt))+" ")])]}},{key:"item.avgRating",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row"},[(item.avgRating)?_c('div',[_vm._v(" "+_vm._s(item.avgRating)+" ")]):_c('div',[_vm._v(" 5.0 ")]),_c('v-icon',{staticClass:"ml-1",attrs:{"small":"","color":"primary"}},[_vm._v("fas fa-star")])],1)]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{directives:[{name:"permissions",rawName:"v-permissions",value:(['EDIT']),expression:"['EDIT']"}],on:{"change":function($event){return _vm.editState(item.zbBarId, item.active)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"no-data",fn:function(){return [_vm._v(" No hay entradas disponibles ")]},proxy:true}])})],1),_c('v-card',{attrs:{"loading":_vm.loadingTable}},[_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},on:{"next":_vm.next,"previous":_vm.previous,"input":_vm.toPage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }